
.tooltip {
    text-transform: none;
    &:hover {
        &:before {
            font-size: 1rem;
            font-weight: normal;
            line-height: 1.2;
            overflow: visible;
            padding: .8rem 1rem;
            white-space: pre-wrap;
            width: 125px;
        }
    }
}
