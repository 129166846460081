.card {

    img {
        display: block;
        margin: 0 auto;
        margin-bottom: 15px;
    }

    h3 {
        color: $grayBlue;
    }

    p {
        font-size: 1.2rem;
    }

}
