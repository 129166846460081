.homepage-banner {
    background-color: $lighter-gray;
    padding: 20px 0;
    h1 {
        color: $gray;
        font-size: 2.4rem;
        font-weight: 400;
    }

    a {
        color: $red;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.inner-banner {
    background-color: $white;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    height: 150px;
    position: relative;
    @media screen and (max-width: 1023px) {
        height: auto;
        min-height: 150px;
    }
    .hilt-conference & {
        height: 430px;
        @media screen and (max-width: 1023px) {
            height: auto;
        }
    }

    .banner-text-container {
        background: rgba(0,0,0,.2);
        color: $white;
        padding: 15px 0;
        position: absolute;
        bottom: 0;
        width: 100%;
        @media screen and (max-width: 1023px) {
            position: static; 
        }
        h1 {
            color: $white;
            font-weight: 300;
        }
    }
}
