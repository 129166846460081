footer {
    background-color: $mediumGray;
    color: $white;

    p {
        color: $white;
        margin: 8px 0 0 20px;
    }

    a {
        color: $white;
        transition: color ease-in-out .15s;
        &:hover {
            color: darken($white, 10%);
        }
    }

    .social-icons  {
        li {
            display: inline-block;
            margin: 10px 5px;
        }
        img {
            height: 40px;
            width: 40px;
        }

    }

    .copyright {
        opacity: .5;
    }
}
