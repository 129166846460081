.slider {
    background: $darkGray;
    background: -moz-linear-gradient(top, $darkGray 0%, $navGray 100%);
    background: -webkit-linear-gradient(top, $darkGray 0%,$navGray 100%);
    background: linear-gradient(to bottom, $darkGray 0%,$navGray 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#282828', endColorstr='#41413f',GradientType=0 );


    .container {
        @media screen and (max-width: 1023px) {
            padding: 0;
        }
    }
    .slider-inner {
        display: flex;
        height: 500px;
        @media screen and (max-width: 1280px) {
            height: 600px;
        }
        @media screen and (max-width: 1023px) {
            height: auto;
        }
    }
    ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        width: 35%;
        @media screen and (max-width: 1023px) {
            width: 100%;
        }
    }

    .slide {
        color: $white;
        display: flex;
        flex-wrap: wrap;
        height: 33.3333%;
        h3, p {
            color: $white;
        }

        p {
            margin-bottom: 0;
        }

        a {
            color: $white;
            font-weight: 700;

        }

        &:hover {
            cursor: pointer;
            .slide-btn span {
                background-color: rgba(255,255,255,1);
            }
        }
        @media screen and (max-width: 1023px) {
            //flex-direction: column;

        }

        .img-container {
            display: none;
            width: 100%;
            @media screen and (max-width: 1023px) {
                display: block;
            }
        }
        img {

            height: auto;
            width: 100%;

        }
        @media screen and (max-width: 1023px) {
            display: none;
            &:first-child {
                display: flex;
            }
        }
    }

    .slide-text {
        padding: 30px;
        width: 80%;
        @media screen and (max-width: 1280px) {
            padding: 10px;
            width: 70%;

        }
        @media screen and (max-width: 1023px) {
            //width: 80%;
            width: 100%;
        }
    }
    .slide-btn {
        align-self: center;
        width: 20%;
        @media screen and (max-width: 1280px) {
            width: 30%;
        }
        @media screen and (max-width: 1023px) {
            display: none;
            // justify-content: flex-end;
            // width: 20%;
        }
        span {
            background-color: rgba(255,255,255,.3);
            border-radius: 50%;
            display: block;
            font-size: 50px;
            height: 60px;
            line-height: 1;
            padding-right: 18px;
            width: 60px;
            text-align: right;
        }
    }
    #slide1 {
        background-color: $red;
        .slide-btn {
            color: $red;
        }
        &:hover {
            background-color: lighten($red, 5%);
            .slide-btn {
                color: $red;
            }
            @media screen and (max-width: 1023px) {
                background-color: $red;
            }
        }
    }

    #slide2 {
        background-color: $grayBlue;
        .slide-btn  {
            color: $grayBlue;
        }
        &:hover {
            background-color: lighten($grayBlue, 5%);
            .slide-btn  {
                color: $grayBlue;
            }
            @media screen and (max-width: 1023px) {
                background-color: $grayBlue;
            }
        }
    }

    #slide3 {
        background-color: $tan;
        .slide-btn  {
            color: $tan;
        }
        &:hover {
            background-color: lighten($tan, 5%);
            .slide-btn  {
                color: $tan;
            }
            @media screen and (max-width: 1023px) {
                background-color: $tan;
            }
        }
    }

    .img-container {
        width: 65%;
        .slide-img {
            background-repeat: no-repeat;
            background-position: top center;
            background-size: cover;
            display: none;
            height: 100%;
            &.selected {
                display: block;
            }
            a {
                display: block;
                height: 100%;
                width: 100%;
            }
        }
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
}
