.table {
    margin-top: 20px;
    table-layout: fixed;
    thead {
        background-color: $red;

        @media screen and (max-width: 1023px) {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }


        th {
            color: $white;
            font-weight: bold;
        }
    }
    tbody {

        tr {
            @media screen and (max-width: 1023px) {
                display: block;
                margin-bottom: 20px;
            }
        }
        tr:nth-child(even) {
            background-color: $lighter-gray;
        }

        td {
            padding: 15px;
            @media screen and (max-width: 1023px) {
                border: none;
                display: block;
                &:before {
                    tent: attr(data-label);
                    display: block;
                    margin-bottom: 10px;
                    font-weight: bold;
                    text-transform: uppercase;
                }
            }
        }
    }


}

.content-title {
    font-weight: bold;
    min-width: 260px;
    a {
        color: $grayBlue;
        @include transition;
        &:hover {
            color: darken($grayBlue, 25%);
        }
    }
}

.content-institution {
    min-width: 150px;
}

.question {
    background-color: $lightRed;
    border-radius: 50%;
    color: $red;
    padding: 3px 8px;
    &:hover {
        cursor: pointer;
    }
}
