html {
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
}

body {
    font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4 {
    font-weight: 700;
    margin-bottom: 0;
}

h1 {
    font-size: 3.6rem;
}

h2 {
    color: $red;
    font-size: 2.4rem;
}

h3 {
    color: $darkGray;
    font-size: 1.8rem;
}

h4 {
    color: $darkGray;
    font-size: 1.4rem;
}

p, li, table {
    color: $darkGray;
    font-size: 1.4rem;
}

p {
    margin-bottom: 1.5rem;
}


a {
    color: $grayBlue;
    @include transition;
    &:hover {
        color: darken($grayBlue, 25%);
    }
}

input[type="text"] {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
}
