ul.original { display: none !important; }

.home,
.page-template-page-advanced-search {
    .search-filter {
        padding: 50px 0 60px 0;
        h2 {
            color: $gray;
        }
        .searchandfilter {
            &>ul {
                background-color: $grayBlue;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                @media screen and (max-width: $tablet) {
                    flex-direction: column;
                }
            }

            .sf-field-search {
                background-color: $white;
                padding: 20px 0;
                width: 100%;
                h4 {
                    display: none;
                }
            }

            li[data-sf-field-input-type='checkbox'] {

                border-right: 4px dotted rgba(255,255,255,.4);
                padding: 30px 30px 50px 30px;
                position: relative;
                width: 33%;
                &:hover {
                    cursor: pointer;
                }
                @media screen and (max-width: $tablet) {
                    border: none;
                    width: 100%;
                }
                &:nth-child(4){
                    border-right: none;
                }

                &:before {
                     content: "\00BB";
                     color: $white;
                     font-size: 55px;
                     left: 50%;
                     position: absolute;
                     bottom: -10px;
                     transform: translateX(-50%) rotate(90deg);
                }
                &.selected {
                    &:after {
                        bottom: -5px;
                          content: '';
                          background-color: $orange;
                          height: 10px;
                          left: 50%;
                          position: absolute;
                          width: 200px;
                          transform: translateX(-50%);
                    }
                }
                h4 {
                    color: $white;
                    font-size: 22px;
                    text-align: center;
                    text-transform: uppercase;
                }
            }

            .sf-field-search {
                input[type="text"] {
                    background-color: $lighter-gray;
                    background-image: none;
                    border: 1px solid lighten($gray, 30%);
                    padding: 20px 80px 20px 20px;
                    width: 100%;
                    transition: background-color ease-in-out .15s;
                    &:focus {
                        &:focus {
                            background-color: lighten($lighter-gray,5%);
                            outline: none;
                        }
                    }
                }
            }

            .sf-field-submit {
                height: 30px;
                position: absolute;
                right: 0;
                top: 20px;
                input[type="submit"] {
                    -webkit-appearance: none;
                    background-color: $red;
                    border: none;
                    color: $white;
                    font-size: 1.4rem;
                    font-weight: 700;
                    padding: 22px 20px 22px 20px;
                    text-transform: uppercase;
                    @include transition;
                    &:hover {
                        background-color: darken($red, 5%);
                        cursor: pointer;
                    }

                    &:focus {
                        background-color: darken($red, 5%);
                        outline: none;
                    }

                    &:-moz-focus-inner {
                    	margin-top:-2px;
                    	margin-bottom: -2px;
                    }
                }
            }

            .sf-tab-content {
                border: 5px solid $orange;
                display: none;
                padding: 30px;
                position: relative;
                &.selected{
                    display: block;
                }

                ul:not(.form-selection) {
                    display: flex;
                    flex-wrap: wrap;
                    @media screen and (max-width: $tablet) {
                        margin-top: 20px;
                    }

                    li {
                        padding: 5px 5px 5px 0;
                        width: 45%;
                        @media screen and (max-width: $tablet) {
                            width: 100%;
                        }
                        label {
                            font-size: 14px;
                            margin-left: 5px;
                        }

                    }
                }
            }

            .form-selection {
                line-height: 1;
                position: absolute;
                right: 30px;
                top: 15px;
                @media screen and (max-width: $tablet) {
                    top: 0;
                }
                li {
                    display: inline-block;
                    padding: 0px 5px;
                    &:first-child {
                        border-right: 1px solid $gray;
                    }

                    &.close a {
                        color: $gray;
                        display: block;
                        font-size: 40px;
                        margin-left: 10px;
                        transform: translatey(7px);
                    }
                }

                a {
                    color: $red;
                }
            }
        }
    }
}

aside {
    .searchandfilter {
        margin-top: 20px;
        .screen-reader-text {
            left: -999em;
            position: absolute !important;
        }

        .sf-tab-content {
            display: none;
            label {
                margin-left: 5px;
            }
            li {
                font-size: 12px;
            }
            .form-selection {
                line-height: 1;
                margin: 10px 0;

                li {
                    display: inline-block;
                    font-size: 12px;
                    padding: 0px 5px;
                    &:first-child {
                        border-right: 1px solid $gray;
                        padding-left: 0;
                    }

                }

                a {
                    color: $red;
                }
            }
        }

        .sf-field-search {
            h4 {
                font-size: 22px;
                margin-bottom: 10px;
                .header-nav-mobile-search & {
                    // hide h4 for mobile search nav
                    display: none;
                }
            }
            input[type="text"] {
                background-color: $lighter-gray;
                background-image: none;
                border: 1px solid lighten($gray, 30%);
                padding: 10px;
                width: 100%;
                transition: background-color ease-in-out .15s;
                &:focus {
                    &:focus {
                        background-color: lighten($lighter-gray,5%);
                        outline: none;
                    }
                }
            }
        }

        ul > li[data-sf-field-input-type="checkbox"] {
            border-bottom: 1px dashed $gray;
            color: $gray;
            font-size: 14px;
            padding: 15px 20px;

            .sf-tab {
                position: relative;
                &:before {
                    content: "\00BB";
                    color: $orange;
                    font-size: 30px;
                    left: -20px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-55%);
                    @include transition;
                }
                &.selected {
                    &:before {
                        transform: translate(2px, -21px) rotate(90deg);
                    }

                }
            }
            &:hover {
                cursor: pointer;
            }
        }

        .sf-field-submit {
            margin-top: 20px;
            input[type="submit"] {
                @extend .button;
            }
        }
    }
}


// Styles for autocomplete jquery ui
form.searchandfilter .sf-field-taxonomy-faculty_staff_keywords,
form.searchandfilter .sf-field-taxonomy-student_keywords {
    display: none !important;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
    border: $orange!important;
    background: $orange!important;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
}
