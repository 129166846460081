.school {
    @extend .tag;
    @extend .is-dark;
    font-size: 1.2rem!important;
    font-weight: 700!important;
    min-width: 55px;
    position: relative;
    text-transform: uppercase;
    &.tooltip {
        cursor: help;
    }

    & + .h {
        // when table cell has a school tag + h tag
        margin-left: 8px;
    }
}

.h {
    @extend .tag;
    background-color: $white!important;
    border: 3px solid $red;
    color: $red!important;
    font-size: 12px!important;
    font-weight: 900;
    padding-left: 0.3em!important;
    padding-right: 0.3em!important;
    &.tooltip {
        cursor: help;
    }
}
