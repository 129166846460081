/* Header */

header {
    background-color: $white;
    position: relative;
}

.header-utility {
    background-color: $darkGray;
    max-height: 50px;

    a {
        color: $white;
        display: inline-block;
        font-weight: bold;
        font-size: 1.2rem;
        padding: 10px;
        text-decoration: underline;
        text-transform: uppercase;
    }

    nav {
        text-align: right;
    }

    li {
        display: inline-block;
    }
}

.search-nav {
    margin-right: 7px;
    .searchandfilter {
        position: relative;
    }
    input[type="submit"] {
        background-color: transparent;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2MS4xIDYxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA2MS4xIDYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxwYXRoIGQ9Ik0zNy40LDQ1LjRsMTQsMTRjMi4yLDIuMiw1LjksMi4yLDguMSwwczIuMi01LjksMC04LjFsLTE0LTE0YzUuOC05LjUsNC42LTIyLTMuNi0zMC4xYy05LjYtOS42LTI1LjEtOS42LTM0LjcsMAoJcy05LjYsMjUuMSwwLDM0LjdDMTUuNSw1MCwyOCw1MS4yLDM3LjQsNDUuNHogTTE0LjQsMTQuMmM1LjYtNS42LDE0LjgtNS42LDIwLjQsMHM1LjYsMTQuOCwwLDIwLjRzLTE0LjgsNS42LTIwLjQsMAoJQzguOCwyOSw4LjgsMTkuOSwxNC40LDE0LjJ6Ii8+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        background-size: 20px 20px;
        border: none;
        padding: 10px;
        position: absolute;
        right: 15px;
        top: 10px;
        z-index: 2;
        @include transition;
        &:hover {
            cursor: pointer;
            opacity: .6;
        }
        &:active,
        &:focus {
            outline: none;
        }
    }

    input[type="text"] {
        background-color: $lighter-gray;
        border: 1px solid lighten($gray, 30%);
        padding: 10px 40px 10px 10px;
        margin-left: 10px;
        min-width: 260px;
        transition: background-color ease-in-out .15s;
        &:focus {
            background-color: lighten($lighter-gray,5%);
            outline: none;
        }

    }
}

.advanced-search {
    line-height: 1;
    margin: 0 0 0 10px;
    text-align: left;
    width: 100px;
}

.header-main {

    .logo-link,
    .tagline-link {
        display: block;
    }

    .tagline-link {
        color: #000;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        font-weight: 500;
        padding-left: 81px;
        @media screen and (max-width: 900px) {
            margin-top: 15px;
            padding-left: 0;
        }

        @media screen and (max-width: 500px) {
            font-size: 10px;
        }

        @media screen and (max-width: 390px) {
            font-size: 9px;
        }

    }
    .logo {
        max-width: 400px;
        .hilt-conference & {
            @media screen and (max-width: 768px) {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .search-nav {
        text-align: right;
        li {
            display: inline-block;

            .screen-reader-text {
                left: -999em;
                position: absolute !important;
            }

        }

        a, label {
            color: $grayBlue;
            font-size: 1.2rem;
            font-weight: bold;
            transition: color ease-in-out .15s;
        }

        label {
            margin-right: 7px;
        }

        a:hover {
            color: darken($grayBlue, 20%);
        }
    }
}

.header-nav-mobile-search {
    background-color: $lighter-gray;
    color: $gray;
    height: 100vh;
    padding: 40px;
    position: absolute;
    right: -3000px;
    width: 100vw;
    z-index: 1;
    top: 50px;
    transition: right ease-in-out .3s;

    &.is-visible {
        right: 0;
    }

    h2 {
        color: $gray;
        margin-bottom: 50px;
        position: relative;
        text-transform: uppercase;
        .close {
            font-size: 3rem;
            position: absolute;
            right: 0;
            top: -15px;
        }
    }
}

.header-nav-mobile {
    background-color: $gray;
    color: $white;
    height: 100vh;
    padding: 40px;
    position: absolute;
    right: -3000px;
    width: 100vw;
    top: 50px;
    transition: right ease-in-out .3s;
    z-index: 1;

    .sub-menu {
        display: none;
        &.is-visible {
            display: block;
        }
    }

    &.is-visible {
        right: 0;
    }

    a {
        color: #fff;
        display: block;
        font-weight: bold;
        padding: 10px 0;
        width: 90%;
    }

    .menu-item-has-children {
        position: relative;
        &:after {
            content: '\25B6';
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 7px;
            transition: .15s ease-in-out;
            z-index: 1;
        }

        &.current-nav {
            &:after {
                transform: rotate(90deg);
            }
        }
    }

    h2 {
        color: $white;
        margin-bottom: 50px;
        position: relative;
        text-transform: uppercase;
        .close {
            font-size: 3rem;
            position: absolute;
            right: 0;
            top: -15px;

        }
    }

    ul > li {
        border-bottom: 1px dashed #69696a;
    }

    ul ul li {
        border: none;
        a {
            padding: 2px 0;
            font-weight: normal;
            font-size: 1.2rem;
        }
    }
}

.burger,
.close,
.search {
    &:hover {
        cursor: pointer;
    }
}

 .mobile-burger-search-nav {
    height: 100%;
    ul {
        display: flex;
        height: 100%;
        justify-content: flex-end;
        li {
            font-size: 28px;
            padding: 0 10px 0 0;
            img {
                height: 20px;
                max-width: 20px;
            }
        }
    }
 }
