// Colors
$darkGray: #282828;
$gray: #58595b;
$light-gray: #89959e;
$lighter-gray: #e0e3e6;
$mediumGray: #88959f;
$navGray: #41413F;

$white: #ffffff;
$red: #a01e33;
$lightRed: lighten($red, 35%);
$orange: #eeb561;
$grayBlue: #4d6f91;
$tan: #8b8179;



// Typography
$size-1: 3.6rem;




$tooltip-background-color: $tan;
