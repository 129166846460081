.sidebar-nav {
    background-color: $lighter-gray;
    padding: 15px 30px;

    li {
        border-bottom: 1px solid $white;
        &:last-child {
            border-bottom: 0;
        }
    }

    a {
        color: $gray;
        display: block;
        font-size: 14px;
        font-weight: 700;
        padding: 15px 0;
        transition: all ease-in-out .15s;
        &.current-page,
        &:hover {
            color: $red;
        }
    }

    ul {
        & > li.current_page_item,
        & > li.current-menu-item {
            &> a {
                color: $red;
            }
        }

        .sub-menu {
            li {
                border-bottom: none;
                margin-left: 20px;
                a {
                    font-size: 12px;
                    font-weight: 500;
                    padding: 3px 0;
                }
            }
        }
    }

    .sub-menu {
        margin-bottom: 15px;
    }
}
