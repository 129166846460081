@media screen and (max-width: 1024px) {
    .container {
        max-width: 100%;
        padding: 0 20px;
        width: 100%;
    }
}

main:focus {
    outline: none;
}

body,
html {
    overflow-x: hidden;
}
