.modal-content {
    background-color: $white;
    border-top: 10px solid $red;
    padding: 40px;
    position: relative;
    .modal-close {
        position: absolute;
        &:before,
        &:after {
            background-color: $darkGray;
        }
        &:hover {
            background: none !important;
        }
    }

    td {
        padding: 0 10px;
    }

    .badge {
        text-align: center;
    }
}
