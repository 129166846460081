.footer-banner {
    background-color: $lighter-gray;
    padding: 20px 0;
    text-align: center;

    span {
        font-size: 1.8rem;
        font-weight: bold;
        font-style: italic;
    }
}
