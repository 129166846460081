.header-nav {
    background-color: $navGray;

    ul.menu {
        display: flex;
        & > li.current_page_item,
        & > li.current_page_ancestor {
            &> a {
                background-color: $red;
            }
        }

        a {
            border-right: 1px solid $gray;
            color: $white;
            display: block;
            font-size: 1.4rem;
            font-weight: 700;
            padding: 15px;
            transition: all ease-in-out .15s;
            &:hover {
                background-color: $red;
            }
        }

        .sub-menu .sub-menu {
            display: none;
        }
    }

    li:last-child a {
        border-right: none;
    }

    ul.sub-menu {
        background-color: darken($lighter-gray, 10%);
        box-shadow: 1px 3px 5px rgba(0,0,0,.5);
        display: none;
        left: 0;
        padding: 20px;
        position: absolute;
        top: 100%;
        width: 250px;
        z-index: 1;

        a {
            border-right: 0;
            border-bottom: 1px dotted $white;
            color: $gray;
            padding: 15px 0;
            &:hover {
                background-color: darken($lighter-gray, 10%);
                color: $red;
            }
        }

        li:first-child a {
            padding-top: 0;
        }

        li:last-child a {
            border-bottom: none;
            padding-bottom: 0;
        }
        .sub-menu {
            margin-left: -20px;
            li {
                padding-left: 20px;
                a {
                    font-size: 12px;
                    font-weight: 500;
                }
            }
        }
    }

    li.menu-item-has-children {
        position: relative;
        &:hover a + ul {
            display: block;
        }
    }
}
