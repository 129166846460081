.hilt-conference {
    main {
        background-color: $red;
    }

    article {
        background-color: $white;
        margin-top: 50px;
        .content {
            padding: 15px 10px;
        }
    }

    .header-main nav {
        text-align: right;
        @media screen and (max-width: 768px) {
            text-align: center;
        }
        a {
            color: $grayBlue;
            display: inline-block;
            position: relative;
            &:before {
                color: $orange;
                content:"\00ab";
                font-size: 40px;
                left: -25px;
                line-height: .4;
                position: absolute;
                top: 0;
            }
        }
    }
}
