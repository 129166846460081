.button {
    background-color: $red;
    border-radius: 0;
    border: none;
    color: $white;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-color ease-in-out .15s;
    &:hover {
        background-color: darken($red, 5%);
        color: $white;
    }
}
