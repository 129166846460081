.homepage-events {
    background-color: $red;
    color: $white;

    &.section {
        padding-top: 0;
    }

    .events-headline {
        background-color: rgba(255,255,255, .15);
        color: $white;
        padding: 5px 15px;
    }

    .column {
        padding-top: 0;
    }

    img {
        float: left;
        margin-right: 25px;
    }

    h3, p, a {
        color: $white;
    }

    a {
        display: block;
        font-weight: bold;
        &:hover {
            color: rgba(255,255,255,.8);
        }
    }
}

.event {
    @extend .is-clearfix;
    padding: 25px 0;
}
