main {
    &.section {
        padding-top: 0;
    }
    article {
        .content a {
            text-decoration: underline;
        }
        
        &:after {
            content: "";
            display: block;
            margin-bottom: 60px;
        }
        .archive {
            li {
                margin-bottom: 20px;
            }

            img {
                margin-right: 20px;
            }
        }
    }
}
